@import '../../styles/variables.scss';

.project-details-wrapper {
  border: 1px solid var(--gray-200);
  border-radius: 8px;
  // height: 149px;

  margin-bottom: 24px;
  .project-details-actions {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    height: 85px;
    .details {
      display: flex;
      flex-direction: row;
      width: auto;
      align-items: center;
      justify-content: start;

      h1 {
        font: 500 30px var(--theme-font);
        color: var(--gray-600);
        line-height: 45px;
        margin-right: 12px;
      }
      .status {
        height: 22px;
        padding: 7px 10px 2px 10px;
        background-color: var(--yellow-100);
        color: var(--yellow-500);
        border-radius: 99px;
      }
    }

    .actions {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }
  }

  .files-deatils {
    height: 24px;
    margin-bottom: 8px;
    color: var(--gray-600);

    margin-bottom: 8px;
    color: var(--gray-600);

    display: flex;
    justify-content: flex-start;
    align-items: center;

    .header {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin: 0 4px 0 0;
    }
    .value {
      font-weight: 400;
      font-size: 14px;
    }
  }
  // Tabs CSS
  .tabs-wrapper {
    .search-bar-container {
      justify-content: space-between;
      padding: 16px;
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid var(--gray-200);

      .filters {
        justify-content: space-between;
        display: flex;
        flex-direction: row;
      }
      .actions {
      }
    }

    .tabs-table-container {
      width: 100%;
      padding: auto;
      height: 40vh;
      overflow-y: scroll;
    }
  }
}

.archived-dialog-wrapper {
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  justify-content: space-between;
  border: 1px solid var(--gray-200);
  padding: 16px;
  height: auto;
  margin-bottom: 16px;
  .content {
    display: flex;
    flex-direction: column;
    justify-content: start;

    .type-of-project {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: var();
    }
    .message {
      font-size: 12px !important;
      line-height: 18px;
      text-align: start;
      padding: 0;
    }

    .actions-button {
      display: flex;
      flex-direction: column;
    }
  }
}

.members-dialog-wrapper {
  .sub-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 8px;
  }
  .search-bar-filter {
    width: 395px;
    border: 1px solid var(--gray-200);
    border-radius: 8px;

    .search {
      border-radius: 8px 8px 0 0 !important;
    }
  }
  .members-list {
    border-radius: 0px 0px 6px 6px;
    height: 188px;
    overflow-y: scroll;
    padding: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;

    .checkbox {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      input {
        height: 16px;
        width: 16px;
        border: 0.5px solid var(--gray-50);
        padding: 10px;
        border-radius: 4px;
      }
    }
  }
}

/* To change color of target file name and project name */
.project-setting-name,
.blue-header-font {
  color: var(--primary-600) !important;
}

.blue-header-font:hover,
.project-setting-name:hover {
  text-decoration: underline;
}
