.project-name {
  font: 600 14px var(--theme-font);
  color: var(--primary-600);
  line-height: 18px;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.project-name-grey {
  font: 600 14px var(--theme-font);
  color: var(--grey-600);
  line-height: 18px;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.source-doc {
  word-break: break-all;

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
  align-items: start;

  .icons {
    padding: 0;
  }
}

.created-at {
  font: 400 12px var(--theme-font);
  color: var(--gray-600);
  display: flex;
  gap: 5px;
}

.archived-project-btn {
  display: flex;
  flex-direction: row;
  gap: 28px;
}
.managers {
  display: flex;
  gap: 16px;

  p {
    text-align: end !important;
  }
}

.selected-file-size {
  color: var(--gray-600);
  font-weight: 500;
  font-size: 12px;
  margin: 4px 0 0 0;
}
.file-size-error {
  color: var(--red-600);
  font-size: 12px;
}

/** Added Styles for changing the default icon to active icon **/
.icon-wrapper {
  display: inline-block;
}

.icon-wrapper .active-icon {
  display: none;
}

.icon-wrapper:hover .default-icon {
  display: none;
}

.icon-wrapper:hover .active-icon {
  display: inline;
}
