.file-upload-box {
  margin: 1rem;
}
.file-name {
  display: flex;
  margin: 0 1rem;
  align-items: center;
  justify-content: space-between;
  .file-size {
    color: var(--gray-500);
    font-size: 14px;
  }
}

.files-container {
  max-height: 42vh !important;
  overflow-y: scroll;
  border-bottom: 1px solid var(--gray-200);
}
@media (min-height: 600px) and (max-height: 700px) {
  .files-container {
    max-height: 43vh;
  }
}
@media (min-height: 700px) and (max-height: 800px) {
  .files-container {
    max-height: 46vh;
  }
}
@media (min-height: 800px) and (max-height: 900px) {
  .files-container {
    max-height: 52vh;
  }
}

@media (min-height: 900px) and (max-height: 1024px) {
  .files-container {
    max-height: 56vh;
  }
}
