:root {
  --gray-50: #f8fafd;
  --gray-100: #f1f5f9;
  --gray-200: #e2e8f0;
  --gray-300: #cbd5e1;
  --gray-400: #e5e8f1;
  --gray-500: #506480;
  --gray-600: #364861;
  --gray-700: #1b263bb2;
  --gray-800: #212c40;

  --gray-900: #1b263b;
  --gray-white: #ffffff;

  --primary-200: #e8edfd;
  --primary-300: #9fb3f9;
  --primary-400: #6e8cf7;
  --primary-600: #1042f1;

  --red-50: #ffeaed;
  --red-100: #ffcfd7;
  --red-200: #ffebeb;
  --red-300: #ffb9c4;
  --red-400: #f9f1f1;
  --red-500: #d74545;
  --red-600: #d94159;
  --red-800: #9f2b3e;

  --green-50: #def7ec;
  --green-100: #bcf0da;
  --green-200: #ddffd7;
  --green-400: #148d67;
  --green-600: #057a55;
  --green-800: #03543f;

  --yellow-100: #fffad9;
  --yellow-300: #e5caa1;
  --yellow-500: #946b2e;
  --white: #fff;

  --theme-font: IBM Plex Sans;
  --shadow-color: #0000001a;
  --surface-100: #f0f2f5;
  --surface-700: #64789b;
}
