.infoIcon {
  width: 45px;
  height: 45px;
  // margin-bottom: 20px;
}
.message {
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: 'IBM Plex Sans';
  color: var(--gray-600);
  padding: 0;
  text-align: center;
  // padding: 0 0 16px 0;
}
