.PdfHighlighter {
  position: absolute;
  overflow: auto;
  width: 100%;
  height: 100%;
}

.PdfHighlighter__highlight-layer {
  position: absolute;
  z-index: 3;
  left: 0;
}

.PdfHighlighter__tip-container {
  z-index: 6;
  position: absolute;
}
.below::before {
  content: '';
  display: block;
  position: absolute;
  top: 0px;
  left: 50%;
  width: 10px;
  height: 10px;
  background: var(--gray-600);
  transform: translateY(-50%) rotate(45deg);
  z-index: 1;
}
.above::before {
  content: '';
  display: block;
  position: absolute;
  bottom: -10px;
  right: 50%;
  width: 10px;
  height: 10px;
  background: var(--gray-600);
  transform: translateY(-50%) rotate(45deg);
  z-index: 1;
}

.PdfHighlighter--disable-selection {
  user-select: none;
  pointer-events: none;
}

.sourcePdf-actions {
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: var(--gray-200);
  button {
    width: 20px;
    height: 20px;
  }
}

/* To remove input type number controls */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* To style pagenumber input element */
.pagenumber-input {
  min-width: 48px;
  max-width: 60px;
  height: 30px;
}
.toogleBtn-wrapper {
  width: 200px;
  display: flex;
  align-items: center;
  background-color: #e9e9e9;
  border-radius: 30px;
  height: 30px;
  padding: 5px;
  gap: 5px;
}
.toogleBtn {
  flex: 1;
  border-radius: inherit !important;
  height: inherit !important;
  background-color: transparent; /* Change to the desired background color */
  color: #000 !important;
}
// For active button
.toogleBtn.active {
  background-color: #2d3a52 !important;
  color: #fff !important; /* Change to the desired text color */
}
