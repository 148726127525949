@import './styles/variables.scss';

/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX8KVElMYYaJe8bpLHnCwDKhdTmdJZGUdLTDw.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX8KVElMYYaJe8bpLHnCwDKhdTmdJZPUdLTDw.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX8KVElMYYaJe8bpLHnCwDKhdTmdJZIUdLTDw.woff2)
    format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1,
    U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX8KVElMYYaJe8bpLHnCwDKhdTmdJZEUdLTDw.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX8KVElMYYaJe8bpLHnCwDKhdTmdJZFUdLTDw.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX8KVElMYYaJe8bpLHnCwDKhdTmdJZLUdI.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX9KVElMYYaJe8bpLHnCwDKjXr8AIxsdO_q.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX9KVElMYYaJe8bpLHnCwDKjXr8AIVsdO_q.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX9KVElMYYaJe8bpLHnCwDKjXr8AIJsdO_q.woff2)
    format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1,
    U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX9KVElMYYaJe8bpLHnCwDKjXr8AI5sdO_q.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX9KVElMYYaJe8bpLHnCwDKjXr8AI9sdO_q.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX9KVElMYYaJe8bpLHnCwDKjXr8AIFsdA.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYXgKVElMYYaJe8bpLHnCwDKhdzeFb5N.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYXgKVElMYYaJe8bpLHnCwDKhdXeFb5N.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYXgKVElMYYaJe8bpLHnCwDKhdLeFb5N.woff2)
    format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1,
    U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYXgKVElMYYaJe8bpLHnCwDKhd7eFb5N.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYXgKVElMYYaJe8bpLHnCwDKhd_eFb5N.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYXgKVElMYYaJe8bpLHnCwDKhdHeFQ.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX9KVElMYYaJe8bpLHnCwDKjSL9AIxsdO_q.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX9KVElMYYaJe8bpLHnCwDKjSL9AIVsdO_q.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX9KVElMYYaJe8bpLHnCwDKjSL9AIJsdO_q.woff2)
    format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1,
    U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX9KVElMYYaJe8bpLHnCwDKjSL9AI5sdO_q.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX9KVElMYYaJe8bpLHnCwDKjSL9AI9sdO_q.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX9KVElMYYaJe8bpLHnCwDKjSL9AIFsdA.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX9KVElMYYaJe8bpLHnCwDKjQ76AIxsdO_q.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX9KVElMYYaJe8bpLHnCwDKjQ76AIVsdO_q.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX9KVElMYYaJe8bpLHnCwDKjQ76AIJsdO_q.woff2)
    format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1,
    U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX9KVElMYYaJe8bpLHnCwDKjQ76AI5sdO_q.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX9KVElMYYaJe8bpLHnCwDKjQ76AI9sdO_q.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX9KVElMYYaJe8bpLHnCwDKjQ76AIFsdA.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX9KVElMYYaJe8bpLHnCwDKjWr7AIxsdO_q.woff2)
    format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX9KVElMYYaJe8bpLHnCwDKjWr7AIVsdO_q.woff2)
    format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX9KVElMYYaJe8bpLHnCwDKjWr7AIJsdO_q.woff2)
    format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1,
    U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX9KVElMYYaJe8bpLHnCwDKjWr7AI5sdO_q.woff2)
    format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX9KVElMYYaJe8bpLHnCwDKjWr7AI9sdO_q.woff2)
    format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./assets/IMB_Plex/zYX9KVElMYYaJe8bpLHnCwDKjWr7AIFsdA.woff2)
    format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

h1 h2 h3 h4 h5 h6 {
  font-family: 'IBM Plex Sans';
}

div {
  font-family: 'IBM Plex Sans';
}

a {
  color: var(--primary-600);
  text-decoration: none;
}

input {
  padding: 0 !important;
}

.theme-blue-btn {
  border: none;
  width: 100%;
  height: 42px;
  border-radius: 8px;
  background: var(--primary-600);
  color: var(--white);
  font: 500 14px var(--theme-font);
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* wrapper for pdf rendering pages */
.page-wrapper {
  height: calc(100vh - 80px);
  margin-top: 72px;
}
/* wrapper for all page */
.global-page-wrapper {
  height: calc(100vh - 72px);
  margin-top: 72px;
  padding: 14px;

  .content-wrapper {
    border: 1px solid var(--gray-200);
    border-radius: 8px;

    .filters-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      border-bottom: 1px solid var(--gray-200);

      .filters-contents {
        display: flex;
        gap: 10px;
        align-items: center;
        flex: 1 1 50%;
        max-width: 50%;

        .search-bar {
          display: flex;
          align-items: center;
          width: 100%;
        }
      }

      .refresh-action-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 18px;

        img {
          cursor: pointer;
        }
      }
    }
  }
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

//global style for close icon

.global-close-btn {
  cursor: pointer;
}

.Mui-selected {
  background-color: #fff;
  font-weight: 600;
}

.sash {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==);
  margin: -5px 0;
  cursor: col-resize;
  background-color: var(--gray-200);
  background-repeat: no-repeat;
  background-position: 50%;
  z-index: 1;
  box-sizing: border-box;
  background-clip: border-box;
}

.sash-container {
  width: 100px;
}

.horizontal-sash {
  width: 100% !important;
  height: 10px;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==);
  margin: -5px 0;
  cursor: col-resize;
  background-color: var(--gray-200);
  background-repeat: no-repeat;
  background-position: 50%;
  z-index: 1;
  box-sizing: border-box;
  background-clip: border-box;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px grey;
  border: 1px solid var(--gray-200);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a9a9a9;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #818589;
}

.moz-available {
  width: -moz-available !important; /* Firefox specific */
}

.webkit-fill-available {
  width: -webkit-fill-available !important; /* WebKit browsers specific */
}

.fill-available {
  width: fill-available !important; /* Standardized property */
}

.grecaptcha-badge {
  visibility: hidden;
}

.recaptcha-usage {
  visibility: hidden;
  font-size: 10px;
  margin-top: 20px;
}
.grecaptcha-badge {
  display: none !important;
}
