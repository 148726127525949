.search-accordion {
  .MuiAccordionSummary-expandIconWrapper {
    position: absolute;
    right: 14px;
    bottom: 5%;
  }
}
.MuiAccordionSummary-content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}
.MuiAccordionSummary {
  position: relative;
}
.MuiAccordionDetails-root {
  margin: 10px !important;
  padding: 0 !important;
}

.MuiPopover-paper {
  border-radius: 8px !important;
  box-shadow: none;
}

.popover-content div:nth-child(2) {
  color: var(--gray-600); /* Default color */
  pointer-events: auto; /* Enable pointer events by default */
}

.popover-content div:nth-child(2).disabled {
  color: var(--gray-300, lightgray); /* Disabled color */
  pointer-events: none !important; /* Disable pointer events */
}

.targetList:last-child {
  padding-bottom: 10px;
}
