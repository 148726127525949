.upload-container,
.table-container {
  width: 93%;
  border: 1px solid var(--gray-200);
  margin: auto;
}

.target-name {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--primary-600);
}
