.Highlight {
  position: absolute;
}

.Highlight__emoji {
  position: absolute;
  color: black;
  opacity: 1;
  font-size: 28px;
}

.Highlight__parts {
  opacity: 1;
}

.Highlight__part {
  cursor: pointer;
  position: absolute;
  background: rgba(255, 226, 143, 1);
  transition: background 0.3s;
}

.Highlight__popup {
  background-color: #3177b0;
  color: #fff;
  border: none;
  width: fit-content;
  padding: 0.4rem;
  margin-bottom: 0.4rem;
  border-radius: 5px;
}

.Highlight--scrolledTo .Highlight__part {
  /* background: #e7b11b !important; */
}
