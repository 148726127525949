.current-project-name {
  display: flex;
  flex-direction: row;
  width: auto;
  align-items: center;
  justify-content: start;
  h1:hover {
    text-decoration: underline;
  }
  h1 {
    font: 500 30px var(--theme-font);
    color: var(--primary-600);
    line-height: 45px;
    margin-right: 12px;
  }
  .status {
    height: 22px;
    padding: 7px 10px 2px 10px;
    background-color: var(--yellow-100);
    color: var(--yellow-500);
    border-radius: 99px;
  }
}

.project-name-grey {
  font: 600 14px var(--theme-font);
  color: var(--grey-600);
  line-height: 18px;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.matching {
  color: var(--green-600);
}

.not-matching {
  color: var(--red-600);
}
