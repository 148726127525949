.page-wrapper {
  .pane-container {
    padding: 20px 0;
    height: 100vh;
  }

  .target-doc-header {
    border: 2px solid #888686;
    padding: 5px 15px;
    width: auto;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  a {
    margin: 3px;
    color: #00aae8;
    text-decoration-line: underline;
  }
}

.collation-accordion {
  .MuiAccordionSummary-root {
    padding: 8px 8px 0 8px;
  }
  .MuiAccordionSummary-expandIconWrapper {
    position: absolute;
    right: 0px;
    padding: 1%;
    top: 68%;
  }
}

.like-dislike-btn {
  cursor: pointer;
}
