.auth-global-page-wrapper {
  display: grid;
  align-items: center;
  justify-items: center;
  min-height: 100vh;

  h3 {
    font-weight: 600;
    font-size: 30px;
    color: var(--gray-600);
  }

  h6 {
    margin: 0;
  }

  .contents {
    border: 1px solid var(--gray-200);
    border-radius: 8px;
    padding: 24px;
    width: 500px;
    height: auto;
    gap: 4;
    box-shadow: 0px 1px 3px 0px var(--shadow-color);

    .input-field-wrapper {
      width: 100%;
      margin-bottom: 16px;
      display: flex;
      align-items: start;
      justify-content: center;
      flex-direction: column;

      .heading {
        font-size: 14px;
        font-weight: 500;
      }

      .input-fields {
        margin-top: 8px;
        margin-bottom: 4px;
        width: 100%;
      }

      .tips {
        font: 500 12px var(--theme-font);
        color: var(--gray-600);
      }
    }

    .action-wrapper {
      margin-top: 4px;
      font-weight: 500;
      font-size: 14px;
    }
  }
}
