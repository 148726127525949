.blue-header-font {
  font-weight: 500;
  font-size: 14px;
  color: var(--gray-600);
}
.grey-header-font {
  font-weight: 500;
  font-size: 14px;
  color: var(--gray-600);
  display: flex;
  flex-direction: row;
  word-break: break-all;
}
.grey-subtitle-font {
  font-weight: 400;
  font-size: 12px;
  color: var(--gray-600);
  display: flex;
  gap: 5px;
}
.green-subtitle-font {
  font-weight: 400;
  font-size: 12px;
  color: var(--green-600);
}
