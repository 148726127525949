.app-bar {
  background-color: var(--gray-white) !important;
  box-shadow: none !important;
  border-bottom: 1px solid var(--gray-200) !important;
  .app-toolbar {
    min-height: 72px;
    display: flex;
    justify-content: space-between;
    padding: 0px;
  }

  .logo-wrapper {
    display: flex;
    min-width: 72px;
    height: 72px;
    justify-content: center;
    align-items: center;
    border-right: 1px solid var(--gray-200);
    .logo {
      width: 65%;
      height: 65%;
      cursor: pointer;
    }
  }
  // .menu{
  //     border-left: 1px solid var(--gray-200);
  //     padding: 0 5px;
  //     .icon-wrapper {
  //         display: flex;
  //         height: 72px;
  //         justify-content: center;
  //         align-items: center;
  //         .members, .logout {
  //             cursor: pointer;
  //         }
  //     }
  // }
}
.arrow-icon {
  margin-top: 5px !important;
}

.bouncing-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bouncing-loader > div {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: #6e6b6b;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
  margin-top: 10px;
  margin-left: 5px;
}
.bouncing-loader > div:first-child {
  margin-left: 10px;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-7px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

.members {
  cursor: pointer;
}
